@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  70% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  70% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@-webkit-keyframes brokenAppleAnimation {
  0%,
  25% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes brokenAppleAnimation {
  0%,
  25% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes penguinStateAnimation {
  100% {
    background-position: calc(33.3% + 33.3% / 1) 0%; } }

@keyframes penguinStateAnimation {
  100% {
    background-position: calc(33.3% + 33.3% / 1) 0%; } }

@-webkit-keyframes penguinWalkingAnimation_left {
  100% {
    background-position: calc(66% + 66% / 1) 0%; } }

@keyframes penguinWalkingAnimation_left {
  100% {
    background-position: calc(66% + 66% / 1) 0%; } }

@-webkit-keyframes penguinWalkingAnimation_right {
  100% {
    background-position: calc(15.3% + 15.3% / 1) 0%; } }

@keyframes penguinWalkingAnimation_right {
  100% {
    background-position: calc(15.3% + 15.3% / 1) 0%; } }

@-webkit-keyframes basketAnimation_left {
  49.99% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); } }

@keyframes basketAnimation_left {
  49.99% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  50% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); } }

@-webkit-keyframes basketAnimation_right {
  49.99% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); } }

@keyframes basketAnimation_right {
  49.99% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); } }

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  background-color: #1b222c; }

.font-Roboto {
  font-family: "Roboto", sans-serif; }

.apple {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  .apple:before {
    content: "";
    display: block;
    padding-top: 117%; }

.apple-red {
  background-image: url(/static/media/apple-red.96d5bc72.svg); }
  .apple-red.broken-apple-animation {
    background-image: url(/static/media/apple-broken-red.8fe80232.svg); }

.apple-green {
  background-image: url(/static/media/apple-green.76f63796.svg); }
  .apple-green.broken-apple-animation {
    background-image: url(/static/media/apple-broken-green.ce8c8066.svg); }

.broken-apple-animation {
  -webkit-animation: brokenAppleAnimation 2s forwards;
          animation: brokenAppleAnimation 2s forwards;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.penguin-body {
  position: relative;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-image: url(/static/media/penguin-sprite.28989193.png); }
  .penguin-body:before {
    content: "";
    display: block;
    padding-top: 114%; }
  .penguin-body.penguin-caught {
    -webkit-animation: penguinStateAnimation 0.5s steps(2) infinite;
            animation: penguinStateAnimation 0.5s steps(2) infinite; }
  .penguin-body.penguin-walking-animation {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-timing-function: steps(2);
            animation-timing-function: steps(2);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate; }
    .penguin-body.penguin-walking-animation.move-left {
      background-position: 66% 0%;
      -webkit-animation-name: penguinWalkingAnimation_left;
              animation-name: penguinWalkingAnimation_left; }
    .penguin-body.penguin-walking-animation.move-right {
      background-position: 100% 0%;
      -webkit-animation-name: penguinWalkingAnimation_right;
              animation-name: penguinWalkingAnimation_right; }

.basket-animation {
  left: -4.5% !important;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: steps(2);
          animation-timing-function: steps(2);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate; }
  .basket-animation.move-left {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    -webkit-animation-name: basketAnimation_left;
            animation-name: basketAnimation_left; }
  .basket-animation.move-right {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
    -webkit-animation-name: basketAnimation_right;
            animation-name: basketAnimation_right; }

