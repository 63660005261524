@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  70% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@keyframes brokenAppleAnimation {
  0%,
  25% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes penguinStateAnimation {
  100% {
    background-position: calc(33.3% + 33.3% / 1) 0%; } }

@keyframes penguinWalkingAnimation_left {
  100% {
    background-position: calc(66% + 66% / 1) 0%; } }

@keyframes penguinWalkingAnimation_right {
  100% {
    background-position: calc(15.3% + 15.3% / 1) 0%; } }

@keyframes basketAnimation_left {
  49.99% {
    transform: rotate(-5deg); }
  50% {
    transform: rotate(5deg); }
  100% {
    transform: rotate(5deg); } }

@keyframes basketAnimation_right {
  49.99% {
    transform: rotate(5deg); }
  50% {
    transform: rotate(-5deg); }
  100% {
    transform: rotate(-5deg); } }

body {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  background-color: #1b222c; }

.font-Roboto {
  font-family: "Roboto", sans-serif; }

.apple {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  .apple:before {
    content: "";
    display: block;
    padding-top: 117%; }

.apple-red {
  background-image: url("./assets/games/catchapples/apple-red.svg"); }
  .apple-red.broken-apple-animation {
    background-image: url("./assets/games/catchapples/apple-broken-red.svg"); }

.apple-green {
  background-image: url("./assets/games/catchapples/apple-green.svg"); }
  .apple-green.broken-apple-animation {
    background-image: url("./assets/games/catchapples/apple-broken-green.svg"); }

.broken-apple-animation {
  animation: brokenAppleAnimation 2s forwards;
  animation-delay: 0.5s; }

.penguin-body {
  position: relative;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-image: url("./assets/games/catchapples/penguin-sprite.png"); }
  .penguin-body:before {
    content: "";
    display: block;
    padding-top: 114%; }
  .penguin-body.penguin-caught {
    animation: penguinStateAnimation 0.5s steps(2) infinite; }
  .penguin-body.penguin-walking-animation {
    animation-duration: 0.5s;
    animation-timing-function: steps(2);
    animation-iteration-count: infinite;
    animation-direction: alternate; }
    .penguin-body.penguin-walking-animation.move-left {
      background-position: 66% 0%;
      animation-name: penguinWalkingAnimation_left; }
    .penguin-body.penguin-walking-animation.move-right {
      background-position: 100% 0%;
      animation-name: penguinWalkingAnimation_right; }

.basket-animation {
  left: -4.5% !important;
  animation-duration: 0.5s;
  animation-timing-function: steps(2);
  animation-iteration-count: infinite;
  animation-direction: alternate; }
  .basket-animation.move-left {
    transform: rotate(-5deg);
    animation-name: basketAnimation_left; }
  .basket-animation.move-right {
    transform: rotate(5deg);
    animation-name: basketAnimation_right; }
